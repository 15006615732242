<script setup lang="ts">
import { Stepper, Step } from '~/src/types/internals'

const props = withDefaults(defineProps<{ steps: Step[]; active: number; direction?: 'vertical' | 'horizontal' }>(), {
  direction: 'vertical',
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isComplete = (value: number, steps: Stepper['steps']) => {
  if (steps) {
    return steps[steps.length - 1].value < props.active
  }
  return value < props.active
}

const stepper = computed(() => {
  let prevChildStepLength = 0

  return props.steps.map((step, index) => {
    const countBasedIndex = index + 1
    prevChildStepLength = prevChildStepLength + 1

    const steps = step.steps?.map((child) => {
      prevChildStepLength = prevChildStepLength + 1
      return {
        ...child,
        value: prevChildStepLength,
      }
    })

    const childStepLength = step.steps?.length || 0
    const value = prevChildStepLength - childStepLength

    return {
      ...step,
      // complete: isComplete(value, steps),
      value,
      count: countBasedIndex,
      steps,
    }
  })
})
</script>
<template>
  <StepperHorizontal
    v-if="direction === 'horizontal'"
    :stepper="stepper"
    :active="active"
  />
  <StepperVertical
    v-else
    :stepper="stepper"
    :active="active"
  />
</template>
